import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldFatutu: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Fatutu?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_fatutu.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Fatutu?</h1>
          <h2>
            Not sure if pulling Fatutu is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>27/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="fatutu"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          Fatutu is the primary healer for Follow-Up based teams. Like Kakania,
          she is able to redistribute DMG Taken and has an egregious amount of
          healing output similar to Sotheby(E). If you’re looking to complete
          your non-Windsong FUA teams, Fatutu is someone to look forward to as
          she provides additional Follow-Ups and a very strong FUA debuff.
        </p>
        <SectionHeader title="Review" />
        <p>
          Fatutu is a 6✦ rarity Arcanist from the Mineral afflatus whose kit is
          oriented around enhancing the DMG Dealt of Follow-Up Attacks and
          providing massive amounts of sustain.
        </p>
        <p>
          Her primary gimmick is [Spirit Shell]. This is a stacking debuff that
          increases DMG Taken and Crit DMG Taken from Follow-Ups by 5% per
          stack. The gimmick here is that whenever an enemy with this debuff is
          hit, Fatutu bounces a stack from them to herself. When Fatutu takes
          damage, she bounces a stack of it to the attacker. The benefit here is
          that whenever she retrieves stacks of the debuff she heals all allies
          and has a 20% chance to gain Moxie whenever she inflicts the debuff.
        </p>
        <p>
          The easiest way to inflict this debuff is through “Unmendable Cracks”,
          her first incantation, which applies 3 stacks of it and also dispels 1
          random debuff from all allies. Whenever she retrieves 7 stacks of
          [Spirit Shell], she’ll cast a rank 1 version of this as a Follow-Up.
        </p>
        <p>
          Let’s move right on to her Ultimate: “Proverb of the Sea”. This is a
          single-target nuke that inflicts all [Spirit Shell] stacks from Fatutu
          onto the target and also inflicts [Shell Beacon]. This debuff makes it
          so that whenever Fatutu retrieves [Spirit Shell], the same amount of
          stacks is reapplied onto the attacker (making it essentially
          infinite).
        </p>
        <p>
          While a majority of her sustain comes from retrieving [Spirit Shell]
          stacks, her “Ocean in the Shell” incantation is incredibly good at it
          too. First, she equally divides all allies’ HP equally (which counts
          as HP Loss, making her a viable option for Semmelweis) and then
          applies [Shared Shells, Shared Fate] to all allies. This buff
          redistributes DMG Taken by the team across all party members. Finally,
          she retrieves all [Spirit Shell] stacks, triggering her passive
          healing.
        </p>
        <h6>Gameplay</h6>
        <p>
          Setting aside personal preferences (god she has such a good character
          design), Fatutu is pretty insane as far as Healers go. With the Moxie
          randomly gained from [Spirit Shell] bounces and the decent uptime on
          [Shell Beacon] she is comfortably able to optimize her Follow-Up
          debuff at the same time as providing massive amounts of passive
          healing.
        </p>
        <p>
          In combination with Flutterpage’s ridiculous Follow-Up boosts, she
          allows characters 37, Lilya(E) and Jiu Niangzi to stay high in the
          rankings. Plus having her own Follow-Ups means that her teams have a
          lower threshold for how many Follow-Ups they need, aiding in some team
          versatility.
        </p>
        <p>
          But that’s not all. Due to some funny coding interactions the HP
          redistribution from “Ocean in the Shell” and the DMG redistribution
          from [Shared Shells, Shared Fate] both count towards stacking HP Loss
          oriented allies, making her a very strong option for Semmelweis Carry
          by quickly stacking “Truth Revealed” to do some very competitive
          numbers.
        </p>
        <p>
          It is also the only other form of DMG redistribution next to Kakania,
          and we know how strong it made that character as a defensive anchor.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Very powerful sustain by redistributing DMG Taken between
                    all allies and having very consistent and surprisingly high
                    passive healing. Makes her usable even outside Follow-Up
                    teams.
                  </li>
                  <li>
                    Access to personal Follow-Up Attacks both helps Flutterpage
                    teams and makes stacking [Spirit Shell] very easy.
                  </li>
                  <li>
                    Semi-consistent cycling of [Shell Beacon] allows her to
                    upkeep massive Follow-Up debuffs while triggering her
                    Insight 1 for healing and Moxie generation.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    [Spirit Shell] gets bounced even when hit by a non-FUA
                    attack which won’t benefit from the effects.
                  </li>
                  <li>
                    Loses all her offensive support if not put in a Follow-Up
                    oriented team.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          While Fatutu is very strong at P0, there are definitely some Portraits
          that allow her to shine even brighter. Luckily, they aren’t high up.
        </p>
        <p>
          Portrait 2 lowers the amount of [Spirit Shells] she needs to retrieve
          to activate her Follow-Up, allowing her more consistency in triggering
          those as well as applying the debuff.
        </p>
        <p>
          Portrait 3’s sauce is increasing the amount of stacks inflicted by
          “Unmendable Cracks” and raising the chance of gaining Moxie from
          casting it from 20% to 25% (includes the Follow-Up casts). A 1/5
          chance to 1/4 is pretty insane, but it’s the stacking here that makes
          the Portrait shine.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Fantastic synergy with units that have hard hitting Follow-Up Attacks.
          Think of 37 primarily and Jiu Niangzi. Lilya(E1) also counts, but
          that’s mostly for when Anjo Nala makes her Ultimate into a Follow-Up
          (allowing it to benefit from [Spirit Shell] stacks.
        </p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Fatutu:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="cracks-in-the-shell"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="that-inquisitive-dear"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="beyond-wonderland"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Cracks In The Shell (Signature), That Inquisitive Dear (Generally
          Good), Beyond Wonderland (Last Case Scenario)
        </p>
        <SectionHeader title="Resonance & Resonance Pattern" />
        <h5>Resonances</h5>
        <h6>R10 Build (Hyper Recommended)</h6>
        <StaticImage
          src="../../../images/reverse/generic/fatutu_r_1.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            The Orphanage (Not Sorry)
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="37"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="flutterpage"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="fatutu"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Anjo Nala </strong>
              </li>
              <li>
                Contracted: Fatutu (or 37, difference is minimal). She very
                consistently casts at least 2 “Unmendable Cracks” per round
                which gives her a fairly decent chance at both gaining Moxie +
                it lets Anjo Nala trigger her Follow-Ups from [Contract]. The
                reason 37 isn’t contracted is because her Ultimate isn’t that
                interesting to cycle and [Shell Beacon] is far superior to keep
                applied to optimize “Supplementary Formula” damage. However,
                Contracting 37 is fine as you’re guaranteed to cast a bunch of
                her Incantations as well whereas Fatutu has diminishing returns
                on the effectiveness of casting with her.
              </li>
              <li>
                This team is pretty straight forward. Generally Flutterpage and
                Anjo Nala don’t require a whole lot of AP, allowing the 4-5
                available AP to be spent on casting with 37 and Fatutu,
                triggering Anjo Nala Follow-Ups for an easy cycle of
                Flutterpage’s Forcefield cycle. This, in combination with fully
                stacked [Spirit Shell] allows 37’s “Supplementary Formula” to
                deal egregious amounts of damage.
              </li>
              <ul>
                <li>
                  37 Replacements - Lilya(E1) when Contracted or Jiu Niangzi in
                  general. Any Carry that cares about the damage of their
                  Follow-Ups can work such as Lucy on farm stages. Windsong is
                  viable but Vila works better due to how much Windsong cares
                  about [Song of Passion].
                </li>
                <li>
                  Flutterpage Replacements - Hard to find but if you really have
                  no other options then Support Triad of Lopera, Argus or
                  Mercuria can generally work their magic.
                </li>
                <li>
                  Anjo Nala Replacements - Kind of the same case as Flutterpage
                  though a bit easier to replace. It also depends on how often
                  your Carry can FUA to help with Flutterpage stacking (if
                  you’re using her at all).
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldFatutu;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Fatutu? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Fatutu is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
